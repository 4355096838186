import React from "react";
import ReactPlayer from 'react-player';
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Carousel
} from "react-bootstrap";

function Inicio() {
  return (
    <>
      <Container fluid>
        <div class="row d-flex justify-content-center">
        <Col lg="10" md="12" sm="12">
        <Row>
          <Col lg="7" md="12" sm="12">
            <Card>
              <Card.Body>
              <Carousel>
                  <Carousel.Item style={{'height':"300px"}} >
                  <a href="https://www.atlas.cdmx.gob.mx/GRE"
                  target="_blank">
                     <img style={{'height':"300px"}}
                      className="d-block w-100"
                      src={require("assets/img/carousel/quimicos_banner.png")
                        .default}  />
                          </a>
                      <Carousel.Caption>
                         <h3> </h3>
                      </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item style={{'height':"300px"}}>
                  <a href="https://serviciosatlas.sgirpc.cdmx.gob.mx/portal/apps/sites/#/alcaldias"
                  target="_blank">
                    <img style={{'height':"300px"}}
                      className="d-block w-100"
                      src={require("assets/img/alcaldias_banner3.png")
                      .default}    />
                        </a>
                      <Carousel.Caption>
                        <h3></h3>
                      </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item style={{'height':"300px"}}>
                  <a href="https://www.atlas.cdmx.gob.mx/monitoreo.html"
                  target="_blank">
                    <img style={{'height':"300px"}}
                      className="d-block w-100"
                      src={require("assets/img/carousel/Modulo_monitoreo.png")
                      .default}   />
                        </a>
                      <Carousel.Caption>
                        <h3></h3>
                      </Carousel.Caption>
                  </Carousel.Item>
              </Carousel>
              </Card.Body>
            </Card>
          </Col>
        <Col lg="5"  md="12" sm="12">
        <Card className="card-tasks">
          <Card.Body>
          <Row>
            <Col xs="12">
              <div>
              <iframe
            style={{width:"100%", height:"300px"}}
            src="https://www.youtube.com/embed/01SeHAKPGgI"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
              </div>
            </Col>
          </Row>
          </Card.Body>
        </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="6" md="6" sm="12" xs="12">
          <Card className="card-stats">
            <Card.Body>
              <Row>
                <Col xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-layers-3 text-success"></i>
                  </div>
                </Col>
                <Col xs="7">
                  <div className="numbers">
                      <p className="card-category">Número de capas en la base
                      de datos del Atlas de Riesgos</p>
                      <Card.Title as="h4">2,784</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  corte al 12 de marzo de 2025
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="6" md="6" sm="12" xs="12">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-cloud-upload-94 text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Última actualización</p>
                      <Card.Title as="h4">12 de marzo de 2025</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  corte al 12 de marzo de 2025
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="6" md="6" sm="12" xs="12">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart-bar-32 text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Número de consultas desde 2019</p>
                        <Card.Title as="h4">155,112,294</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Hasta el 28 de febrero de 2025
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="6" md="6" sm="12" xs="12">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart-pie-36 text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Número de consultas 2025</p>
                        <Card.Title as="h4">2,366,916</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Hasta el 28 de febrero de 2025
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
        <Col lg="6"  md="6" sm="12">
          <Card className="card-tasks">
            <Card.Body>
            <Row>
              <Col xs="12">
                <div>
                <a href="https://serviciosatlas.sgirpc.cdmx.gob.mx/portal/apps/sites/#/forin"
                target="_blank">
                  <img style={{'height':"300px"}}
                    className="d-block w-100"
                    src={require("assets/img/forin.png")
                    .default}   />
                      </a>
                </div>
              </Col>
              </Row>
              </Card.Body>
            </Card>
          </Col>
              <Col lg="6"  md="6" sm="12">
                <Card className="card-tasks">
                  <Card.Body>
                  <Row>
                    <Col xs="12">
                      <div>
                      <a href="https://www.atlas.cdmx.gob.mx/pdf/impacto_socioeconomico_2020.pdf"
                      target="_blank">
                        <img style={{'height':"300px"}}
                          className="d-block w-100"
                          src={require("assets/img/carousel/impacto_banner_2020.png")
                          .default}   />
                            </a>
                      </div>
                    </Col>
                    </Row>
                    </Card.Body>
                  </Card>
                </Col>
        </Row>
        <Row>
        <Col lg="6" sm="12">
          <Card className="card-stats">
          <Card.Header>
            <Card.Title as="h4">Análisis de peligros, vulnerabilidad y exposición</Card.Title>
            <p className="card-category">¿Mi familia y yo estamos expuestos a algún riesgo donde vivimos?</p>
          </Card.Header>
            <Card.Body>
              <Row>
                <Col xs="12">
                  <div>
                    <a href="https://www.atlas.cdmx.gob.mx/analisisn3/"
                    target="_blank" className="card-category">
                    <img className="d-block w-100"
                      src={require("assets/img/analisis.PNG")
                      .default}   />
                    </a>
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <hr></hr>
              <div className="stats">
                <i className="far fa-thumbs-up mr-1"></i>
                Módulo recomendado
              </div>
            </Card.Footer>
          </Card>
        </Col>
        <Col lg="6" sm="12">
          <Card className="card-stats">
          <Card.Header>
            <Card.Title as="h4">REUSE</Card.Title>
            <p className="card-category">En la zona en la que vives o trabajas, ¿sabes cuáles son las principales emergencias diarias?</p>
          </Card.Header>
            <Card.Body>
              <Row>
                <Col xs="12">
                  <div>
                    <a href="https://www.atlas.cdmx.gob.mx/RUSE/"
                    target="_blank" className="card-category">
                    <img className="d-block w-100"
                      src={require("assets/img/reuse3.PNG")
                      .default}   />
                    </a>
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <hr></hr>
              <div className="stats">
                <i className="far fa-thumbs-up mr-1"></i>
                Módulo recomendado
              </div>
            </Card.Footer>
          </Card>
        </Col>
        </Row>
            </Col>
                </div>
      </Container>
    </>
  );
}

export default Inicio;
